/**
 * ## ItemModalProvider.tsx ##
 * This file contains ItemModalProvider component
 * @packageDocumentation
 */

import React from 'react';

import once from 'lodash/once';

import { WithDeleted } from '@common/typescript/objects/WithDeleted';
import {
	ItemProvider, ItemProviderContext,
	ItemProviderProps,
} from '@common/react/components/Core/ItemProvider/ItemProvider';
import { BaseParams } from '@common/typescript/objects/BaseParams';
import { useModal } from '@common/react/components/Modal/ModalContextProvider';

export interface ItemModalProviderState<T extends WithDeleted> {
	/**
	 * loading state from ItemProvider
	 */
	loading: boolean;
	/**
	 * current item id
	 */
	id: number;
	/**
	 * item state at ItemProvider. The value of item.id may differ from the id from the context. For example, when loading a new element
	 */
	item: T;
	/**
		title attribute for button EditableTable ColumnActions.Modal
	 */
	buttonTitle: string;
	/**
		render that node at EditableTable ColumnActions.Modal button
	 */
	buttonChildren: React.ReactNode;
}

export interface ItemModalProviderActions {
	/**
		function to open a modal window
		@param item - object from which the id or new element will be taken, depending on loadBeforeOpen and id
		@param params - Options for loading a new item
	 */
	openModal: (item, params?: BaseParams) => void;
}

export interface ItemModalProviderContext<T extends WithDeleted> {
	state: ItemModalProviderState<T>;
	actions: ItemModalProviderActions;
}

/**
 * This is the description of the interface
 *
 * @interface ItemModalProviderProps
 * @typeParam T - T Any WithDeleted entity
 */
export interface ItemModalProviderProps<T extends WithDeleted> extends Omit<ItemProviderProps<T>, 'id' | 'skipInitLoad' | 'children'> {
	/**
	 * React children or child render callback
	 */
	children: React.ReactNode | ((context: ItemProviderContext<T>, modalContext: ItemModalProviderContext<T>) => React.ReactNode);
	/**
	 * callback to get modal Props
	 */
	getModalProps: (item: T, setOpen: React.Dispatch<React.SetStateAction<boolean>>) => BaseParams;
	/**
	 * modal window content
	 * callback to get modal Props or React children
	 */
	render: ((item: T, setOpen: React.Dispatch<React.SetStateAction<boolean>>) => React.ReactNode) | React.ReactNode;
	/**
		render that node at EditableTable ColumnActions.Modal button
	 */
	buttonChildren?: React.ReactNode;
	/**
		title attribute for button EditableTable ColumnActions.Modal
	 */
	buttonTitle?: string;
	/**
		callback that will be called after the element has changed and the modal window has been closed
	 */
	onCloseAfterSave?: () => void;
	/**
		determines whether the modal should be closed after saving
	 */
	closeAfterSave?: boolean;
	/**
		determines whether the element needs to be loaded before opening the modal window.
		 (For elements with id <0, the element is not loaded, but is taken from an argument in openModal)
	 */
	loadBeforeOpen?: boolean;
	/**
		callback that will be called after the modal is opened
		@param item - new item
		@param prevItem - previous item
	 */
	onOpenModal?: (item, prevItem) => void;
	/**
		default open state for modal
	 */
	defaultOpen?: boolean;
	/**
		default id state. (used at inner ItemProvider)
	 */
	defaultId?: number;
	/**
		callback to take id from openModal argument
		@param item - new item
	 */
	getIdAtOnOpenModal?: (item) => number;
}

export const createItemModalProviderContext = once(<T extends WithDeleted, >() => React.createContext({} as ItemModalProviderContext<T>));

export const useItemModalProviderContext = <T extends WithDeleted = any, >() =>
	React.useContext<ItemModalProviderContext<T>>(createItemModalProviderContext<T>());

/**
 * ItemModalProvider component.
 *
 * usage examples:
 *  - <ItemModalProvider type="someType" getModalProps={...} render={...}>{React.ReactNode}</ItemModalProvider>
 *  - <ItemModalProvider type="someType" getModalProps={...} render={...}>{(itemProviderContext, modalContext) => React.ReactNode}</ItemModalProvider>
 *
 * @typeParam T - T Any {WithKey}
 * @param p - ItemModalProviderProps<T>
 * @type {React.FC<ItemModalProviderProps>}
 * @returns React.ReactElement
 */

const MessageHandler:React.FC<{setMessage, open, setError}> = ({ open, setMessage, setError }) => {
	React.useEffect(() => {
		if (!open) {
			setTimeout(() => {
				setMessage('');
				setError('');
			}, 200);
		}
	}, [open]);
	return <></>;
};

const ItemModalProvider = <T extends WithDeleted>(p: ItemModalProviderProps<T>) => {
	const {
		children,
		render,
		getModalProps,
		buttonChildren = <i className="fa fa-eye" />,
		buttonTitle = 'Edit',
		onCloseAfterSave,
		closeAfterSave = true,
		loadBeforeOpen: defaultLoadBeforeOpen = false,
		onOpenModal,
		defaultOpen = false,
		defaultId = -1,
		getIdAtOnOpenModal = (item) => item?.id,
		...props
	} = p;
	const Context = createItemModalProviderContext();
	const [open, setOpen] = React.useState(defaultOpen);
	const [params, setParams] = React.useState(props.additionalParams);
	const [id, setId] = React.useState(defaultId);
	const refId = React.useRef({ id: defaultId });
	const [saved, setSaved] = React.useState(false);
	const globalModalContext = useModal();

	React.useEffect(() => {
		if (!open) setSaved(false);
	}, [open]);

	return (
		<ItemProvider<T>
			{...props}
			additionalParams={params}
			id={id}
			skipInitLoad
			onLoad={(res) => {
				onOpenModal && onOpenModal(res, undefined);
				setOpen(true);
			}}
			onLoadRequestError={(error) => {
				props?.onLoadRequestError?.(error);
				setId(defaultId);
			}}
			onSave={() => {
				setSaved(true);
				if (closeAfterSave) {
					setOpen(false);
					onCloseAfterSave && onCloseAfterSave();
				}
			}}
		>
			{(value) => {
				const modalContext = {
					state: {
						id, loading: value.state.loading, buttonChildren, buttonTitle, item: value.state.item,
					},
					actions: {
						openModal: (item, params?: BaseParams, loadBeforeOpen = defaultLoadBeforeOpen) => {
							const newId = getIdAtOnOpenModal(item);
							params && setParams(params);
							if (newId < 0) {
								value.actions.setItem(item);
								setId(newId);
								refId.current.id = newId;
								setOpen(true);
								onOpenModal && onOpenModal(item, value.state.item);
							} else if (loadBeforeOpen && newId !== refId.current.id) {
								setId(newId);
								refId.current.id = newId;
							} else {
								!loadBeforeOpen && value.actions.setItem(item);
								setOpen(true);
								onOpenModal && onOpenModal(item, value.state.item);
							}
						},
					},
				};

				const modalProps = value.state.item ? getModalProps(value.state.item, setOpen) : {};

				return <>
					<MessageHandler setMessage={value.actions.setMessage} setError={value.actions.setError} open={open} />
					<Context.Provider value={modalContext}>
						{typeof children === 'function' ? children(value, modalContext) : children}
						{globalModalContext.renderModal?.(open, () => setOpen(false), {
							...modalProps,
							destroyOnClose: modalProps.destroyOnClose ?? true,
							afterClose: () => {
								modalProps.afterClose && modalProps.afterClose();
								if (saved) {
									onCloseAfterSave && onCloseAfterSave();
								}
							},
							children: typeof render === 'function' ? render(value.state.item, setOpen) : render,
						})}
					</Context.Provider>
				</>;
			}}
		</ItemProvider>
	);
};

export default ItemModalProvider;
