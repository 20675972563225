import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import Message from 'antd/lib/message';
import Radio from 'antd/lib/radio';
import Select from 'antd/lib/select';
import Tag from 'antd/lib/tag';

import { ItemsProvider, useItemsProviderContext } from '@common/react/components/Core/ItemsProvider/ItemsProvider';
import { useApplicationContext } from '@common/react/components/Core/Application/Application';
import { getChatName } from '@common/react/components/Chat/ChatUtils';
import { ChatKind, ChatPlugins } from '@common/react/components/Chat/Chat';
import LinkWithPrevLocation from '@common/react/components/UI/LinkWithPrevLocation/LinkWithPrevLocation';
import Chats from '@common/react/components/Chat/Chats';
import { getUserName } from '@common/react/utils/utils';
import { BaseUserWithAvatar } from '@common/typescript/objects/BaseUser';
import { AllChatsPlugin } from '@common/react/components/Chat/AllChatsPlugin/AllChatsPlugin';
import { OnlineFilterPlugin } from '@common/react/components/Chat/OnlineFilterPlugin/OnlineFilterPlugin';
import { TemplatePlugin } from '@common/react/components/Chat/TemplatePlugin/TemplatePlugin';
import { SendLater } from '@common/react/components/Chat/SendLater/SendLater';
import Avatar from '@common/react/components/Forms/Avatar';
import { EmailPlugin } from '@common/react/components/Chat/Email/EmailPlugin';
import { FilePlugin } from '@common/react/components/Chat/FilesPlugin/FilesPlugin';
import FiltersComponent from '@common/react/components/UI/FiltersComponent/FiltersPropsHandler';
import SelectFilter from '@common/react/components/Forms/SelectFilter/SelectFilter';
import { RegularMessagePlugin } from '@common/react/components/Chat/RegularMessagePlugin/RegularMessagePlugin';

import {
	SupportChatListFilterByUsers,
	SupportChatListFilterByUsersNames,
} from '@commonTuna/react/objects/SupportChatListFilterByUsers';

import { UserRole, User } from '@app/objects/User';
import AddSupportChatPopup from '@app/components/Pages/Admin/SupportChats/AddSupportChatPopup';
import SupportChatUsersSettings from '@app/components/Pages/Admin/SupportChats/SupportChatUsersSettings';
import CallPatient from '@app/components/UI/CallPatient/CallPatient';
import SendEmailModal from '@app/components/UI/SendEmailModal/SendEmailModal';
import { getAvatar } from '@app/components/Utils';
import SimpleChatSettingsProvider from '@app/components/Pages/Admin/Chats/SimpleChatSettingsProvider/SimpleChatSettingsProvider';
import { Location } from '@app/objects/Location';
import '@app/scss/components/supportChats.scss';
import '@app/scss/pages/chatsPage.scss';
import LocationSelect from '@app/components/UI/LocationSelect/LocationSelect';
import { BuildData } from '@app/objects/BuildData';
import UserAvatarWithStatus from '@app/components/UI/UserAvatarWithStatus';

const { Option } = Select;

const getUserPickSupport = (chat, userId): JSX.Element => {
	const users = chat.contacts.filter((user) => user.remoteId !== userId);
	const user = users[0] ?? chat.contacts[0];
	if (users.length === 1 || chat.contacts.length === 1) {
		return (
			<div
				className="chat-list__item-avatar"
				title={getUserName(user)}
			>
				<UserAvatarWithStatus
					onClick={() => undefined}
					user={{ ...user, avatar: user.avatar ? getAvatar(user, undefined, true) : '' } as User}
				/>
			</div>
		);
	}

	return (
		<div className="chat-list__item-avatar-multiple">
			{users.slice(0, 4).map((user: BaseUserWithAvatar & {color?: string}) => (
				<div
					className="chat-list__item-avatar chat-list__item-avatar_min"
					key={user.id}
					title={getUserName(user)}
				>
					<UserAvatarWithStatus
						size={24}
						onClick={() => undefined}
						user={{ ...user, avatar: user.avatar ? getAvatar(user, undefined, true) : '' } as User}
					/>
				</div>
			))}
		</div>
	);
};

export const SupportChatSettingsProvider: React.FC = ({ children }) => {
	const { checkUserAccess, request, getBuildData } = useApplicationContext();
	const navigate = useNavigate();
	const buildData = getBuildData<BuildData>();
	const helpBotUserId = buildData?.helpBotUserId;

	const context = useItemsProviderContext<Location>();

	const { state } = context;

	const templatePlugin = React.useMemo(() => {
		return {
			...TemplatePlugin,
			options: {
				saveRequest: 'supportChatTemplate',
				loadRequest: 'supportChatTemplateList',
				options: [
					'user_firstName',
					'user_lastName',
					'location_name',
					'location_address',
					'location_phone',
				],
				getData: (chat, user) => {
					const patient = chat.contacts.find((q) => q.remoteId === null);
					const location = state.items.find((q) => q.id === chat.locationId);

					/* eslint-disable */
					// @ts-ignore
					return {
						user_firstName: patient?.firstName,
						user_lastName: patient?.lastName,
						location_name: location?.nameEn,
						location_address: location?.addressEn,
						location_phone: location?.phone,
					};
					/* eslint-enable */
				},
			},
		};
	}, [state.items]);

	const getPatientId = (portalId: number, patientId) => {
		request<number>('getPatientId', {
			id: portalId,
		}).then((res) => {
			if (res > 0) {
				navigate({
					pathname: `/patient-editor/${res}`,
					search: 'mode=view',
				});
				patientId.current = res;
			}
		}).catch(Message.error);
	};

	const chatListHeaderComponents = [
		({ user }) => (
			<AddSupportChatPopup
				user={user}
				render={(show) => (
					<button
						className="btn btn-primary"
						title="Add Chat"
						onClick={show}
						type="button"
					>
						<i className="fa fa-plus" />
					</button>
				)}
			/>
		),
		ChatPlugins.Searching,
		ChatPlugins.OnlineFilter,
		ChatPlugins.Archive,
		({
			user, selectChat, handleChange, filters, pageSettings,
		}) => {
			return <FiltersComponent
				additionalParams={filters}
				popoverClassName="dashboard-filters-component"
				filtersHandler={{
					loadAllChats: (value) => !!value,
				}}
				filtersClean={() => {
					handleChange({
						locationId: undefined,
						loadAllChats: false,
						filterByUsers: undefined,
					});
				}}
				getPopupContainer={(element) => element.closest('.chats-page') || document.body}
				tooltipValues={<div>
					<div className="filter-element" data-param="locationId" style={{ flex: '1 1 20%' }}>
						<LocationSelect
							params={filters}
							onChange={(event) => handleChange(event)}
						/>
					</div>
					{AllChatsPlugin.chatsListHeaderComponent && checkUserAccess([UserRole.Admin]) && <div
						className="filter-range-element"
						data-param="loadAllChats"
						style={{ flex: '0 0 auto' }}
					>
						{AllChatsPlugin.chatsListHeaderComponent({
							handleChange, filters, selectChat, pageSettings, user,
						})}
					</div>}
					<div className="filter-ratio-element responsive-radio" data-param="filterByUsers" style={{ whiteSpace: 'nowrap' }}>
						<SelectFilter
							additionalParams={filters}
							param="filterByUsers"
							onChange={handleChange}
							currentValue={SupportChatListFilterByUsersNames[filters.filterByUsers]}
							defaultValue=""
						>
							<Option value="">All</Option>
							{Object.keys(SupportChatListFilterByUsersNames).map((key) => (
								<Option value={key} key={key}>{SupportChatListFilterByUsersNames[key]}</Option>
							))}
						</SelectFilter>
					</div>
					<div className="filter-ratio-element" data-param="chatsWithUnreadMessages">
						<Radio.Group
							value={filters.chatsWithUnreadMessages}
							className="pull-right ml10"
							name="chatsWithUnreadMessages"
							onChange={(ev) => handleChange({ chatsWithUnreadMessages: ev.target.value })}
						>
							<Radio.Button value>Only unread messages</Radio.Button>
							<Radio.Button value={false}>Only read messages</Radio.Button>
							<Radio.Button>All</Radio.Button>
						</Radio.Group>
					</div>
				</div>}
			/>;
		},
	];

	const filePlugin = React.useMemo(() => ({
		...FilePlugin,
		options: {
			request: 'remoteFineUploader',
		},
	}), []);

	return (
		<SendEmailModal button={() => <></>}>
			{(_, modalContext) => <SimpleChatSettingsProvider
				handleAddChatByNewMessage={(message, chatFilters) =>
					chatFilters.filterByUsers === SupportChatListFilterByUsers.HasPatientOrSupportMessage
					&& message.user?.id === helpBotUserId}
				avatarSettings={{
					getChatAvatar: getUserPickSupport,
					getUserAvatar: (user) => (
						<div className="chat-message-list-component__item-avatar">
							<UserAvatarWithStatus
								user={{
									...user,
									avatar: getAvatar(user, '', !user.role),
								} as any}
								onClick={() => undefined}
								size={36}
							/>
						</div>
					),
					notificationAvatar: (state) => (
						<Avatar
							object={{ avatar: state.avatar ? `/remote/${state.avatar}` : undefined }}
							type=""
							readonly
							showOpenIcon={false}
							showDownload={false}
							withModal={false}
							className="avatar-container chat-message-list-component__item-avatar"
							firstName={state.firstName ?? 'N'}
							lastName={state.lastName ?? 'N'}
						/>
					),
				}}
				onNotificationClick={(message: any, defaultHandle) => {
					if (message.appointmentEmailId) {
						modalContext.actions.openModal({ id: message.appointmentEmailId });
					} else {
						defaultHandle();
					}
				}}
				onMessageClick={(e, message: any) => {
					if (message.appointmentEmailId) {
						modalContext.actions.openModal({ id: message.appointmentEmailId });
					}
				}}
				plugins={{
					[ChatPlugins.Regular]: {
						...RegularMessagePlugin,
						options: {
							customNode: (message) => message.sentAsSms && <Tag
								style={{ float: 'right', margin: '0 0 5px 10px' }}
								title="Sent as SMS"
								color="orange"
							>
								sms
							</Tag>,
						},
					},
					[ChatPlugins.AllChats]: AllChatsPlugin,
					[ChatPlugins.OnlineFilter]: OnlineFilterPlugin,
					[ChatPlugins.Typing]: undefined,
					[ChatPlugins.Template]: templatePlugin,
					[ChatPlugins.SendLater]: SendLater,
					[ChatPlugins.Files]: filePlugin,
					[ChatPlugins.Email]: {
						...EmailPlugin,
						message: {
							...EmailPlugin.message,
							render: ({ message }) => <div style={{ cursor: (message as any).appointmentEmailId ? 'pointer' : undefined }}>
								{EmailPlugin.message?.render({ message } as any)}
							</div>,
						},
						options: {
							customNode: (message) => <Tag
								style={{ float: 'right', marginLeft: '10px', marginRight: 0 }}
								title="Sent as Email"
								color="blue"
							>
								email
							</Tag>,
						},
					},
				}}
				listComponent={[
					ChatPlugins.SendLater,
				]}
				messageAttachmentsBefore={[ChatPlugins.SendLater]}
				withRemoteId
				chatListHeaderSettings={chatListHeaderComponents}
				sendButtonWrapper={ChatPlugins.SendLater}
				messagesHeaderComponents={[
					({ currentChat }) => {
						const patientContact = currentChat
							? currentChat.contacts.find((contact) => !contact.remoteId || !(contact.remoteId > 0)) : undefined;
						return patientContact && currentChat
					&& (
						<SendEmailModal
							initialEmail={patientContact.email}
							portalId={patientContact.id}
							button={(onClick, disabled, title) => {
								return (
									<button
										type="button"
										className="btn btn-sm btn-primary pull-right ml10"
										title={title || 'Send Email'}
										onClick={onClick}
										disabled={disabled}
									>
										<i className="fa fa-envelope" />
									</button>
								);
							}}
						/>
					);
					},
					({ currentChat }) => {
						const patientContact = currentChat
							? currentChat.contacts.find((contact) => !contact.remoteId || !(contact.remoteId > 0)) : undefined;
						return patientContact && currentChat && (
							<CallPatient
								className="btn btn-sm btn-primary pull-right"
								patientAvatar={patientContact.avatar}
								patientName={`${patientContact.firstName} ${patientContact.lastName}`}
								portalId={patientContact.id}
								chatId={currentChat.id}
							>
								<i className="fa fa-phone" />
							</CallPatient>
						);
					},
					ChatPlugins.VideoChat,
					ChatPlugins.VoiceCall,
					ChatPlugins.CompleteChat,
					ChatPlugins.LeaveChat,
					({ patientId, currentChat, user }) => currentChat.kind === ChatKind.Group && user.role === UserRole.Admin
					&& currentChat.contacts.some((item) => item.remoteId === user.id)
					&& (
						<SupportChatUsersSettings
							user={user}
							currentSupportChat={currentChat}
							getPatientId={(id) => getPatientId(id, patientId)}
						/>
					),
				]}
				renderChatName={({ currentChat: currentSupportChat, user, patientId }) => {
					const patientContact = currentSupportChat
						? currentSupportChat.contacts.find((contact) => !contact.remoteId || !(contact.remoteId > 0)) : undefined;
					return (
						<>
							<LinkWithPrevLocation
								to={{}}
								onClick={() => {
									patientContact && getPatientId(patientContact.id, patientId);
								}}
							>
								{getChatName(currentSupportChat, user.id)}
							</LinkWithPrevLocation>
						</>
					);
				}}
				pageSettings={{
					path: '/support-chats',
					chatIdUrlKey: 'supportChatId',
					archive: 'supportArchive',
				}}
				storageName="supportChats"
				requests={{
					chat: 'supportChat',
					chatUser: 'supportChatUser',
					chatMessage: 'supportChatMessage',
					chatMessageAccess: 'supportChatMessageAccess',
					typing: 'supportTyping',
					completeChat: 'supportChatArchiveRemote',
					getChat: 'getSupportChat',
					loadMessages: 'supportChatMessageList',
					loadChats: 'supportChatList',
					getOrCreatePersonalChat: 'getOrCreatePersonalChat',
				}}
				notificationTypes={{
					chat: 'SupportChat',
					chatUser: 'ChatUser',
					chatMessage: 'SupportChatMessage',
					chatReaction: 'SupportChatEmojiReaction',
					chatMessageAccess: 'SupportChatMessageAccess',
					typing: 'SupportTyping',
					updateChatCounterNotification: 'UpdateSupportChatCounterNotification',
					updateUserMessagesCountNotification: 'UpdateUserSupportMessagesCountNotification',
				}}
				chatsFilters={{
					loadAllChats: false,
					archive: false,
					online: true,
					filterByUsers: SupportChatListFilterByUsers.HasPatientOrSupportMessage,
				}}
				formSettings={{
					underFormLabel: 'Press Ctrl+Enter to send a message',
					maxMessageLength: 1000,
					maxAttachmentsCount: 5,
					allowPasteImages: false,
					sendByEnter: false,
				}}
				formButtons={[
					ChatPlugins.Files,
					ChatPlugins.Template,
					ChatPlugins.VideoMessage,
					ChatPlugins.VoiceMessage,
				]}
				chatStoreSettings={{
					getCurrentChat: (state) => state.supportChats.currentChat,
					getChatStore: (state) => ({
						chats: state.supportChats.chats,
						chatsLoaded: state.supportChats.chatsLoaded,
					}),
					getMessages: (chatId) => (state) => state.supportChats.messages[chatId] || undefined,
					userUnviewedMessagesCountName: 'unviewedSupportMessagesCount',
				}}
				title="Support Chats"
				basePath="/remote/"
			>
				{children}
			</SimpleChatSettingsProvider>}
		</SendEmailModal>
	);
};

const SupportChats: React.FC = () => {
	return (
		<ItemsProvider<Location>
			type="locationPage"
			skipInitLoad={false}
		>
			<SupportChatSettingsProvider>
				<div className="support-chat" />
				<Chats />
			</SupportChatSettingsProvider>
		</ItemsProvider>
	);
};

export default SupportChats;
