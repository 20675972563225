import { ChartData } from 'chart.js';

export enum SystemTypes
{
	All = -1,
	Tuna = 0,
	Chrono = 1,
	Nopali = 2,
	ZocDoc = 3,
	PhoneCall = 4,
	Inquiry = 5
}

export interface SystemTypeGroupElem {
	image?: any;
	icon?: string;
	caption: string;
	value: SystemTypes;
}

export enum AppointmentCounterInfoState {
	Sources = 0,
	Statuses = 1,
	Types = 2,
	Doctors = 3,
}

export const AppointmentCounterInfoStateNames = {
	[AppointmentCounterInfoState.Sources]: 'Sources',
	[AppointmentCounterInfoState.Statuses]: 'Statuses',
	[AppointmentCounterInfoState.Types]: 'Types',
	[AppointmentCounterInfoState.Doctors]: 'Doctors',
};

export const systemTypeGroup: Array<SystemTypeGroupElem> = [
	{ image: require('@app/images/tuna.png'), caption: 'NorthernTuna Patient Portal', value: SystemTypes.Tuna },
	{ image: require('@app/images/drchrono.jpg'), caption: 'Chrono', value: SystemTypes.Chrono },
	{ image: require('@app/images/nopaliLogo.jpg'), caption: 'Nopali', value: SystemTypes.Nopali },
	{ image: require('@app/images/zocDoc.png'), caption: 'ZocDoc', value: SystemTypes.ZocDoc },
	{ image: require('@app/images/phoneCall.jpg'), caption: 'Phone Call', value: SystemTypes.PhoneCall },
	{ icon: 'fa fa-globe', caption: 'Inquiry', value: SystemTypes.Inquiry },
	{ caption: 'All', value: SystemTypes.All },
];

export interface IWithCounterItem {
	name: string;
	color: string;
	value: number;
	id: number;
}

export interface BaseDoughnutData extends ChartData<'doughnut', Array<number>> {
	ids: Array<number>,
	error: any,
	emailsCount: number;
	isLoading: boolean;
}
