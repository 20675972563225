import React from 'react';

import Tooltip from 'antd/lib/tooltip';
import Tag from 'antd/lib/tag';

import { Chat } from '@common/react/components/Chat/Chat';
import { BaseUserWithAvatar } from '@common/typescript/objects/BaseUser';
import { getUserNameWithEmail } from '@common/react/utils/utils';
import { useChatSettingsProviderContext } from '@common/react/components/Chat/ChatSettingsProvider';
import { RequestButton } from '@common/react/components/UI/LoadingButton/LoadingButton';

import UserAvatarWithStatus from '@app/components/UI/UserAvatarWithStatus';
import { User } from '@app/objects/User';

interface Props {
	currentChat: Chat;
}

interface PopupMemberItemProps {
	user: BaseUserWithAvatar;
	chatId?: number;
}

export const PopupMemberItem: React.FC<PopupMemberItemProps> = ({ user, chatId }) => {
	const context = useChatSettingsProviderContext();

	if (!context?.state) throw 'need ChatSettingsContext';

	const { state: { requests } } = context;
	return (
		<div
			style={{ width: '100%' }}
		>
			<span
				style={{
					width: '100%',
					display: 'grid',
					gridTemplateColumns: '1fr 3fr 20px',
					alignItems: 'center',
				}}
			>
				<div style={{
					display: 'flex',
					alignItems: 'center',
				}}
				>
					<UserAvatarWithStatus user={user as User} />
				</div>
				<div>
					<span>
						{getUserNameWithEmail(user)}
					</span>
				</div>
				{chatId && (
					<RequestButton
						requestType={requests.chatUser}
						requestProps={{
							chatId,
							userId: user.id,
							deleted: true,
						}}
						other={{
							style: {
								background: 'none',
								border: 'none',
								padding: 0,
								marginLeft: 5,
								verticalAlign: 'text-top',
							},
						}}
						className="btn btn-default"
						title="Remove user from chat"
					>
						<i
							className="fa fa-times"
						/>
					</RequestButton>
				)}
			</span>
		</div>
	);
};

const ChatMembers: React.FC<Props> = ({ currentChat }) => {
	const context = useChatSettingsProviderContext();

	if (!context?.state) throw 'need ChatSettingsContext';

	const { state: { requests, getUser } } = context;
	const currentUser = getUser();

	return (
		<Tooltip
			overlayClassName="list-tooltip"
			color="white"
			overlayInnerStyle={{ padding: '6px 8px 1px' }}
			title={currentChat.contacts.map((user) => (
				<div key={user.id} className="inline-select-item inline-select-item_sm">
					<span>
						{currentChat.userId === user.id && (
							<i
								style={{ marginRight: 5 }}
								className="fa fa-at"
								title="Creator"
							/>
						)}
						{user.firstName}
						{' '}
						{user.lastName}
						{currentChat.userId === currentUser?.id && (
							<RequestButton
								requestType={requests.chatUser}
								requestProps={{
									chatId: currentChat.id,
									userId: user.id,
									deleted: true,
								}}
								other={{
									style: {
										background: 'none',
										border: 'none',
										padding: 0,
										marginLeft: 5,
										verticalAlign: 'text-top',
									},
								}}
								className="btn btn-default"
								title="Remove user from chat"
							>
								<i
									className="fa fa-times"
								/>
							</RequestButton>
						)}
					</span>
				</div>
			))}
		>
			<Tag className="chat-component__headline__count">
				{currentChat.contacts.length}
				{' '}
				members
			</Tag>
		</Tooltip>
	);
};

export default ChatMembers;
