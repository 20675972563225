import React from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ChatMessage } from '@common/react/components/Chat/Chat';
import { RequestButton } from '@common/react/components/UI/LoadingButton/LoadingButton';
import { useChatSettingsProviderContext } from '@common/react/components/Chat/ChatSettingsProvider';
import { Actions } from '@common/react/components/Chat/SignalRChats';

interface Dictionary<T> {
	[Key: string]: T;
}

interface MedicalTranscriptionBase {
	objectId: number;
	objectType: string;
	objectField: string;
	data?: Dictionary<string>;
}

export interface MedicalTranscriptionNotificationData extends MedicalTranscriptionBase {
	text: string;
}

interface MedicalTranscriptionRequest extends MedicalTranscriptionBase {
	path: string;
}

interface ComponentProps extends MedicalTranscriptionRequest {
	chatMessage: ChatMessage;
	className?: string;
	requestType?: string;
	caption?: string;
}

const MedicalTranscribeButton : React.FC<ComponentProps> = ({
	path,
	objectId,
	objectType,
	objectField,
	data,
	className = '',
	requestType = 'medicalTranscription',
	caption = 'Transcribe',
	chatMessage,
}) => {
	const context = useChatSettingsProviderContext();

	if (!context?.state) throw 'need ChatSettingsContext';
	const {
		state: {
			storageName,
			chatStoreSettings: {
				getActionCreators,
			},
		},
	} = context;

	const dispatch = useDispatch();
	const actions: Actions = React.useMemo(() => bindActionCreators(getActionCreators(), dispatch), []);

	return <>
		{'loading' in chatMessage ? <div className="chat-message-btn__bordered" /> : null}
		<RequestButton<MedicalTranscriptionRequest>
			requestType={requestType}
			type="button"
			requestProps={{
				path,
				objectId,
				objectType,
				objectField,
				data,
			}}
			other={{
				style: { marginTop: 5 },
			}}
			beforeAction={() => {
				actions.updateMessageContent({
					...chatMessage,
					loading: true,
				}, storageName);
				return true;
			}}
			className={`btn btn-primary pull-right chat-message-btn btn-sm ${className}`}
			successMessage="The voice message is being processed, please wait a few minutes."
			isLoading={chatMessage.loading}
		>
			{caption}
		</RequestButton>
	</>;
};

export default MedicalTranscribeButton;
