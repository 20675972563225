import * as React from 'react';

import { ChatPlugin, ChatPlugins } from '@common/react/components/Chat/Chat';
import { useChatSettingsProviderContext } from '@common/react/components/Chat/ChatSettingsProvider';

const EmailMessage: any = ({ message }: any) => {
	const context = useChatSettingsProviderContext();
	const plugins = context?.state?.plugins;
	const customNode = plugins?.[ChatPlugins.Email]?.options?.customNode;
	return <>
		{customNode?.(message)}
		<div
			className="email-message"
			style={{
				display: 'flex',
				alignItems: 'center',
				padding: '1rem',
				borderRadius: '0.5rem',
				backgroundColor: 'aliceblue',
				width: 'fit-content',
			}}
		>
			<i className="fa fa-envelope" style={{ color: 'dodgerblue', fontSize: '2rem' }} />
			<div style={{
				fontSize: '1.5rem',
				fontWeight: 'bold',
				marginLeft: '1rem',
				width: 'fit-content',
			}}
			>
				{message.text}
			</div>
		</div>
	</>;
};

const LastMessage = ({ message, userId }) => <div className="chat-list__item-last-message chat-list__item-last-message__email">
	<i className="fa fa-envelope" />
	{message.userId === userId ? 'You: ' : ''}
	{' '}
	sent email
</div>;

export const EmailPlugin: ChatPlugin = {
	message: {
		render: EmailMessage,
		lastMessage: LastMessage,
		notification: EmailMessage,
	},
};
