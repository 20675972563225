import React from 'react';

import { Chat } from '@common/react/components/Chat/Chat';
import { RequestButton } from '@common/react/components/UI/LoadingButton/LoadingButton';
import Avatar from '@common/react/components/Forms/Avatar';
import ListWithTooltip from '@common/react/components/UI/ListWithTooltip/ListWithTooltip';

import AddUserToSupportChatPopup from '@app/components/Pages/Admin/SupportChats/AddUserToSupportChatPopup';
import { User } from '@app/objects/User';

interface Props {
	user: User;
	currentSupportChat: Chat;
	getPatientId: (portalId: number) => void;
}

const AvatarNode: React.FC<{item, chatId}> = ({ item, chatId }) => {
	return <div className="chat-list__item-avatar chat-list__item-avatar__actions">
		<Avatar
			type=""
			object={{ avatar: item.avatar ? `/remote/${item.avatar}` : '' }}
			readonly
			className="avatar-container"
			showOpenIcon={false}
			showDownload={false}
			withModal={false}
			firstName={item.firstName}
			lastName={item.lastName}
		/>
		<RequestButton
			requestType="supportChatUser"
			requestProps={{
				chatId,
				userId: item.remoteId,
				deleted: true,
			}}
			className="btn btn-default btn-close"
			type="button"
		>
			<i className="fa fa-times" />
		</RequestButton>
	</div>;
};

const SupportChatUsersSettings: React.FC<Props> = ({ user, currentSupportChat, getPatientId }) => {
	return (
		<>
			<AddUserToSupportChatPopup
				user={user}
				chat={currentSupportChat}
				render={(show) => (
					<button
						className="btn btn-sm btn-info pull-right"
						title="Add user to chat"
						onClick={show}
						type="button"
					>
						<i className="fa fa-plus" style={{ width: 'unset', height: 'unset', lineHeight: 'unset' }} />
					</button>
				)}
				getPatientId={getPatientId}
				autocompleteParams={{
					support: true,
					excludeUserIds: currentSupportChat.contacts
						.filter((user) => user.remoteId && user.remoteId > 0)
						.map((user) => user.remoteId),
				}}
			/>
			<div className="chat-component__actions__users pull-right">
				<ListWithTooltip
					array={currentSupportChat.contacts.filter((user) => user.remoteId && user.remoteId > 0)}
					getValue={(item) => item}
					getValueNode={(item) => <AvatarNode item={item} chatId={currentSupportChat.id} />}
					getFirstValue={(item) => <AvatarNode item={item} chatId={currentSupportChat.id} />}
					count={8}
				/>
			</div>
		</>
	);
};

export default SupportChatUsersSettings;
