export enum SupportChatListFilterByUsers {
    HasPatientOrSupportMessage = 0,
    HasPatientMessage = 1,
    HasSupportMessage = 2,
}

export const SupportChatListFilterByUsersNames = {
	[SupportChatListFilterByUsers.HasPatientOrSupportMessage]: 'Has Patient Or Support Message',
	[SupportChatListFilterByUsers.HasPatientMessage]: 'Has Patient Message',
	[SupportChatListFilterByUsers.HasSupportMessage]: 'Has Support Message',
};
