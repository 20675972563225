import * as React from 'react';

import { saveAs } from 'file-saver';

import { BaseParams } from '@common/typescript/objects/BaseParams';
import {
	FetchButton,
	FetchButtonProps,
	FetchMethod,
	SuccessProps,
} from '@common/react/components/UI/LoadingButton/LoadingButton';

interface Props extends Omit<FetchButtonProps<BaseParams>, 'onSuccess'> {
	fileName?: string;
	onSuccess?: (blob: Blob, props: SuccessProps<BaseParams>) => void;
	children: React.ReactNode;
}

export const DownloadFileButton = (props: Props) => {
	const {
		method = FetchMethod.POST,
		children,
		fileName = 'file.pdf',
		errorMessage = 'Something went wrong while downloading the file.',
		onSuccess: propsOnSuccess,
		...rest
	} = props;

	const onSuccess = ({ res, success, error }) => {
		res.blob().then((blob) => {
			saveAs(blob, res.headers.get('name') || fileName);
			propsOnSuccess?.(blob, { res, success, error });
		});
	};

	return <FetchButton
		method={method}
		onSuccess={onSuccess}
		errorMessage={errorMessage}
		{...rest}
	>
		{children}
	</FetchButton>;
};
