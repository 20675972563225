import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Message from 'antd/lib/message';

import { TypeKeys } from '@common/react/store/PhoneCall';

import { Feature } from '@commonTuna/react/objects/CompanyFeature';

import { ApplicationState } from '@app/store';
import { request } from '@app/components/Api';
import CompanyFeatureAccess from '@app/components/UI/CompanyFeatureAccess/CompanyFeatureAccess';

interface Props {
	patientId?: number;
	inquiryId?: number;
	portalId?: number;
	chatId?: number;
	className?: string;
	patientNumber?: string;
	patientAvatar: string;
	patientName: string;
	title?: string;
	disabled?: boolean;
}

interface PhoneCallResponse {
	token: string;
}

const CallPatient: React.FC<Props> = (props) => {
	const dispatch = useDispatch();
	const phoneCallState = useSelector((state: ApplicationState) => state.phoneCall);
	const [hasDevice, setHasDevice] = React.useState<boolean>(false);
	const isBusy = useSelector((state: ApplicationState) => state.phoneCall.isBusy);
	const isDisabled = React.useMemo(() => isBusy || !hasDevice, [hasDevice, isBusy]);
	const title = React.useMemo<string>(
		() =>
			(!hasDevice ? 'Input Microphone'
				: isBusy ? 'You have active call' : 'Call Patient'),
		[hasDevice, isBusy],
	);

	const {
		className,
		children,
		patientId,
		inquiryId,
		portalId,
		patientNumber,
		patientAvatar,
		patientName,
		chatId,
	} = props;

	const handleSuccess = (token: string, phoneNumber?: string) => {
		dispatch({
			type: TypeKeys.UPDATE,
			data: {
				...phoneCallState,
				token,
				patientId,
				inquiryId,
				portalId,
				patientAvatar,
				patientNumber: phoneNumber || patientNumber,
				patientName,
				chatId,
				isActive: true,
				isBusy: true,
			},
		});
	};

	const checkDevices = async () => {
		if (!navigator.mediaDevices) return Promise.resolve();
		const devices = await navigator.mediaDevices?.enumerateDevices();
		setHasDevice(devices.filter((device) => device.kind === 'audioinput').length > 0);
	};

	React.useEffect(() => {
		checkDevices().catch(Message.error);
		navigator.mediaDevices?.addEventListener('devicechange', checkDevices);
		return () => navigator.mediaDevices?.removeEventListener('devicechange', checkDevices);
	}, []);

	const handleClick = (e) => {
		e.preventDefault();

		console.log(portalId, chatId);

		if (portalId) {
			request<string>('getPatientPhoneNumber', { id: portalId })
				.then((phoneNumber) => {
					console.log(phoneNumber);
					request<PhoneCallResponse>('phoneCallAccessToken', {})
						.then((res) => {
							console.log(res);
							handleSuccess(res.token, phoneNumber);
						})
						.catch(Message.error);
				})
				.catch(Message.error);
			return;
		}

		if (!patientNumber) {
			Message.error('Phone number is not set');
			return;
		}

		request<PhoneCallResponse>('phoneCallAccessToken', {})
			.then((res) => handleSuccess(res.token))
			.catch((err) => Message.error(err));
	};

	return (
		<CompanyFeatureAccess feature={Feature.SupportChats}>
			<button
				type="button"
				className={className}
				title={props.title ?? title}
				onClick={handleClick}
				disabled={props.disabled || isDisabled}
			>
				{children}
			</button>
		</CompanyFeatureAccess>
	);
};

export default CallPatient;
