import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { BaseUser } from '@common/typescript/objects/BaseUser';
import { Notification } from '@common/typescript/objects/Notification';
import { useChatSettingsProviderContext } from '@common/react/components/Chat/ChatSettingsProvider';
import { useNotificationGliderContext } from '@common/react/components/Chat/NotificationGliderProvider';
import { ChatPlugins, EmojiReaction } from '@common/react/components/Chat/Chat';
import { NotificationAction } from '@common/typescript/objects/NotificationAction';
import { handleUrl } from '@common/react/utils/FIltersParamsFromUrl/FiltersParamsFromUrl';
import { useApplicationContext } from '@common/react/components/Core/Application/Application';

const ReactionNotificationHandler: React.FC = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const { api, placement } = useNotificationGliderContext();
	const context = useChatSettingsProviderContext();
	const state = context?.state;
	const { subscribe, getUser } = useApplicationContext();
	const user = getUser();

	const handleNotification = React.useCallback((incomingNotification: Notification<BaseUser>) => {
		if (incomingNotification.objectType !== state.notificationTypes.chatReaction
			|| location.pathname === state.pageSettings.path
			|| incomingNotification.action !== NotificationAction.Add || state?.modalMode
			|| user?.id === incomingNotification.data.remoteId || user?.id === incomingNotification.data.userId) return;

		const reaction = incomingNotification.data as EmojiReaction;

		const notificationComponent = state.plugins[ChatPlugins.Reactions]?.message?.notification;

		api?.info({
			icon: state.avatarSettings.notificationAvatar(reaction.user),
			message: `${reaction.user.firstName} ${reaction.user.lastName}`,
			description:
	<>
		{
			notificationComponent ? notificationComponent({
				message: reaction,
				withRemoteId: false,
				contacts: [],
			}) : 'Reaction'
		}
	</>,
			className: 'notification-glider',
			onClick: () => {
				if (state.openModalFromNotification) {
					context.actions.setModalMode((prev) => true);
					handleUrl(
						{ chatId: reaction.object.chatId, messageId: reaction.objectId },
						location,
						navigate,
						undefined,
						'',
						true,
					);
				} else {
					navigate(`${state.pageSettings.path}?${
						state.pageSettings.chatIdUrlKey}=${reaction.object.chatId}&messageId=${reaction.objectId}`);
				}
				api.destroy();
			},
			placement,
		});
	}, [location.pathname, state?.modalMode, user]);

	React.useEffect(subscribe(handleNotification), [handleNotification]);

	return null;
};

export default ReactionNotificationHandler;
