import * as React from 'react';

import Select from 'antd/lib/select';
import Segmented from 'antd/lib/segmented';

import { isUndefined } from '@common/react/utils/guards';

import '@commonTuna/react/scss/components/multipleFilter.scss';
import { FilterMode, FilterModeNames } from '@commonTuna/react/objects/FilterMode';

interface ComponentProps {
	className?: string;
	placeholder?: string;
	value: Array<number>;
	onChange: (value: Array<number>, filterMode?: FilterMode) => void;
	selectOptions: any;
	maxTagCount?: number;
	maxTagTextLength?: number;
	maxTagPlaceholder?: React.ReactNode | ((omittedValues: Array<number>) => React.ReactNode);
	allowClear?: boolean;
	redClearButton?: boolean;
	style?: React.CSSProperties;
	disabled?: boolean;
	getPopupContainer?: (node) => HTMLElement;
	defaultFilterMode?: FilterMode;
}

interface OptionType {
	key: number;
	value: number;
	label: string;
}

const EnumMultipleFilter: React.FC<ComponentProps> = ({
	className,
	placeholder = 'Select...',
	value,
	onChange,
	selectOptions,
	maxTagCount = 2,
	maxTagTextLength = 20,
	maxTagPlaceholder,
	allowClear = true,
	redClearButton = false,
	style,
	disabled = false,
	getPopupContainer,
	defaultFilterMode,
}) => {
	const [filterMode, setFilterMode] = React.useState<FilterMode | undefined>(defaultFilterMode);

	const withFilterMode = React.useMemo(() => !isUndefined(defaultFilterMode), [defaultFilterMode]);

	return (
		<div
			className={`${redClearButton
				? 'multiple-filter-with-red-clear-button'
				: 'multiple-filter'} ${className} ${allowClear ? 'with-clear' : ''}`}
		>
			<div className="input-group">
				<Select<Array<number>, OptionType>
					mode="multiple"
					placeholder={placeholder}
					value={value}
					onChange={(value) => onChange(value, filterMode)}
					maxTagCount={maxTagCount}
					maxTagTextLength={maxTagTextLength}
					maxTagPlaceholder={maxTagPlaceholder}
					style={style}
					disabled={disabled}
					getPopupContainer={getPopupContainer}
					filterOption={(input, option) =>
						(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
					}
					options={Object.keys(selectOptions).map((key) => {
						return {
							key: +key,
							value: +key,
							label: selectOptions[+key],
						};
					})}
					allowClear={allowClear}
				/>
				{withFilterMode && <div className="input-group-btn">
					<Segmented
						value={filterMode}
						onChange={(keyValue) => {
							if (value.length > 0) {
								onChange(value, +keyValue);
							}
							setFilterMode(+keyValue);
						}}
						options={Object.keys(FilterModeNames).map((key) => ({
							value: +key,
							label: FilterModeNames[+key],
						}))}
					/>
				</div>}
			</div>
		</div>
	);
};

export default EnumMultipleFilter;
