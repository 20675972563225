import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Notification } from '@common/typescript/objects/Notification';
import { BaseUser } from '@common/typescript/objects/BaseUser';
import { useNotificationGliderContext } from '@common/react/components/Chat/NotificationGliderProvider';
import {
	createChatSettingsProviderContext,
	useChatSettingsProviderContext,
} from '@common/react/components/Chat/ChatSettingsProvider';
import { ChatMessage, ChatMessageType } from '@common/react/components/Chat/Chat';
import { NotificationAction } from '@common/typescript/objects/NotificationAction';
import { handleUrl } from '@common/react/utils/FIltersParamsFromUrl/FiltersParamsFromUrl';
import { useApplicationContext } from '@common/react/components/Core/Application/Application';
import SignalRChats from '@common/react/components/Chat/SignalRChats';

const ChatNotificationHandler: React.FC<{ withRemoteId?: boolean }> = ({ withRemoteId }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const context = useChatSettingsProviderContext();
	const state = context.state;
	const { api, placement } = useNotificationGliderContext();
	const Context = createChatSettingsProviderContext();
	const { subscribe, getUser } = useApplicationContext();
	const user = getUser<BaseUser>();

	const handleNotification = React.useCallback((incomingNotification: Notification<BaseUser>) => {
		if (incomingNotification.objectType !== state?.notificationTypes.chatMessage
			|| state?.pageSettings.path === location.pathname
			|| incomingNotification.action !== NotificationAction.Add || state?.modalMode
			|| user?.id === incomingNotification.data.remoteId || user?.id === incomingNotification.data.userId) return;

		const message = incomingNotification.data as ChatMessage;

		if (message.viewed) return;

		const notificationComponent = state.plugins[message.chatMessageType]?.message?.notification;

		api?.info({
			icon: state.avatarSettings.notificationAvatar(message.user),
			message: `${message.user?.firstName} ${message.user?.lastName}`,
			description: <Context.Provider value={context}>
				{
					notificationComponent
						? notificationComponent({
							message,
							withRemoteId: false,
							contacts: [],
						})
						: 'no data'
				}
			</Context.Provider>,
			className: 'notification-glider',
			onClick: () => {
				const defaultHandle = () => {
					if (state.openModalFromNotification) {
						context.actions.setModalMode((prev) => true);
						handleUrl(
							{ chatId: message.chatId },
							location,
							navigate,
							undefined,
							'',
							true,
						);
					} else {
						navigate(`${state.pageSettings.path}?${state.pageSettings.chatIdUrlKey}=${message.chatId}`);
					}
				};
				if (state.onNotificationClick) {
					state.onNotificationClick(message, defaultHandle);
				} else {
					defaultHandle();
				}
				api.destroy();
			},
			placement,
		});
	}, [location.pathname, state?.modalMode, user]);

	React.useEffect(subscribe(handleNotification), [handleNotification]);

	return <SignalRChats withRemoteId={withRemoteId} />;
};

export default ChatNotificationHandler;
