import React from 'react';

import * as Yup from 'yup';

import ItemModalProvider from '@common/react/components/Core/ItemModalProvider/ItemModalProvider';
import { ItemEditor } from '@common/react/components/Core/ItemEditor/ItemEditor';
import { useItemProviderContext } from '@common/react/components/Core/ItemProvider/ItemProvider';
import { simpleStringValidator } from '@common/react/utils/validationHelpers';
import { useModal } from '@common/react/components/Modal/ModalContextProvider';

import { Feature } from '@commonTuna/react/objects/CompanyFeature';

import { AppointmentFile } from '@app/objects/AppointmentFile';
import { AppointmentEmail } from '@app/objects/AppointmentEmail';
import { useCompanyFeatureProviderContext } from '@app/components/UI/CompanyFeaturesSetter/CompanyFeaturesSetter';

import SendEmailReadonly, { messageKey } from '@app/components/UI/SendEmailModal/SendEmailReadonly';
import SendEmailEdit from '@app/components/UI/SendEmailModal/SendEmailEdit';

interface EmailFormValues {
	id: number;
	to: Array<string>;
	cc: Array<string>;
	bcc: Array<string>;
	subject: string;
	html: string;
	attachments: Array<AppointmentFile>;
	patientId?: number;
	inquiryId?: number;
	portalId?: number;
	archive: boolean;
	read?: boolean;
	useCustomMailboxConfig?: boolean;
}

interface SendEmailModalProps {
	initialEmail?: string;
	setChanged: (value) => void;
	setOpen: (value) => void;
	initialPatientId?: number;
	button?: (onClick: (item?) => void, disabled: boolean, title: string) => JSX.Element;
	inquiryId?: number;
	portalId?: number;
	viewMode?: boolean;
	appointmentEmail?: AppointmentEmail;
	afterSubmit?: (appointmentEmail) => void;
	onClose?: () => void;
	defaultVisible?: boolean;
	patientName?: string;
	onCloseAfterChange?: () => void;
	readonly?: boolean;
	onSendError?: (err: string) => void;
}

const SendEmailForm: React.FC<Omit<SendEmailModalProps, 'onCloseAfterChange' | 'onClose' | 'button'>> = ({
	initialEmail, initialPatientId, inquiryId, portalId, viewMode, ...props
}) => {
	const {
		patientName: propsPatientName, readonly, setChanged, setOpen,
	} = props;
	const context = useItemProviderContext();
	const { openSuccessMessage } = useModal();

	return (
		<ItemEditor<EmailFormValues>
			readonly={context.state.item.id > 0}
			getInitialValues={(values) => (values)}
			afterSubmit={(item) => {
				if (!(item.id > 0)) setOpen(false);

				openSuccessMessage?.(readonly ? 'Saved' : 'Email was sent', { key: messageKey });
			}}
			formikProps={{
				enableReinitialize: true,
			}}
			edit={(formikBag, deleteItem, { loading, error }) => <SendEmailEdit loading={loading} error={error} />}
			view={(item) => <SendEmailReadonly
				item={item}
				patientName={propsPatientName}
				setOpen={setOpen}
				setItem={context.actions.setItem}
				setChanged={setChanged}
				save={context.actions.update}
			/>}
		/>
	);
};

const SendEmailModal: React.FC<Omit<SendEmailModalProps, 'setChanged' | 'setOpen'>> = (props) => {
	const {
		children,
		onCloseAfterChange,
		onClose,
		button,
		initialPatientId,
		inquiryId,
		portalId,
		initialEmail,
		onSendError,
		...rest
	} = props;
	const { openErrorMessage } = useModal();
	const { checkFeatureAccess } = useCompanyFeatureProviderContext();
	const emailFeatureIsEnabled = checkFeatureAccess(Feature.Email);
	const [changed, setChanged] = React.useState(false);

	const handleClose = () => {
		onClose && onClose();
		if (changed) {
			onCloseAfterChange && onCloseAfterChange();
		}
		setChanged(false);
	};

	return <ItemModalProvider<EmailFormValues>
		getModalProps={(item) => {
			return {
				width: 900,
				afterClose: handleClose,
				styles: { mask: { zIndex: 1001 } },
				className: 'send-email-modal',
				wrapClassName: `send-email-modal__wrap ${item.id < 0 ? '' : 'send-email-modal__wrap__readonly'}`,
				header: null,
				footer: null,
			};
		}}
		onCloseAfterSave={onCloseAfterChange}
		closeAfterSave={!onCloseAfterChange}
		validationSchema={Yup.object().shape({
			to: Yup.array().test('Required field!', 'Invalid Email', (value) => {
				if (!value || value?.length === 0) return false;
				return value?.filter((email) => {
					try {
						Yup.string().email().validateSync(email);
						return true;
					} catch (e) {
						return false;
					}
				}).length === value.length;
			}),
			subject: simpleStringValidator,
			html: simpleStringValidator,
		})}
		loadBeforeOpen
		type=""
		saveRequest="sendReply"
		clearForSubmit={(values) => ({
			...values,
			patientId: values.patientId,
			inquiryId: values.inquiryId,
			portalId: values.portalId,
			to: Array.isArray(values.to) ? values.to.join(',') : values.to,
			cc: Array.isArray(values.cc) ? values.cc.join(',') : values.cc,
			bcc: Array.isArray(values.bcc) ? values.bcc.join(',') : values.bcc,
			subject: values.subject,
			html: values.html,
			attachments: values.id > 0 ? undefined : values.attachments,
			archive: values.archive,
			patient: undefined,
			useCustomMailboxConfig: values.useCustomMailboxConfig,
		} as any)}
		loadRequest="getAppointmentEmail"
		render={(item, setOpen) => <SendEmailForm
			setOpen={setOpen}
			// appointmentEmail={item?.id > 0 ? item : undefined}
			setChanged={setChanged}
			{...props}
		/>}
		onSaveRequestError={onSendError}
		onRequestError={(err) => openErrorMessage?.(err)}
		buttonTitle="Show"
	>
		{(context, modalContext) => <>
			{typeof children === 'function' ? children(context, modalContext) : children}
			{button && button(
				() => modalContext.actions.openModal({
					id: -1,
					to: initialEmail ? [initialEmail] : [],
					cc: [],
					bcc: [],
					subject: '',
					html: '',
					attachments: [],
					patientId: initialPatientId ?? -1,
					inquiryId,
					portalId,
					archive: false,
					useCustomMailboxConfig: false,
				}),
				!emailFeatureIsEnabled,
				emailFeatureIsEnabled ? '' : 'Email feature is disabled',
			)}
		</>}
	</ItemModalProvider>;
};

export default SendEmailModal;
